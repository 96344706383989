import React from 'react';

const Index = () => {
  return (
    <>
      <div>
        <section id="about">
          <div className="row">
            <div className="small-12 large-8 large-offset-2 columns text-center">
              <div
                className="profile-photo"
                style={{
                  backgroundImage: `url(assets/images/mainprofile.jpeg)`
                }}
              ></div>
              <div className="details">
                <h2>
                  Hi, I'm George. I...
                  <br /> <span id="writing-text"></span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 column">
              <div className="cta text-center">
                <a
                  className="my-button cta-button expand form-button"
                  href="mailto:georgeuwabor@gmail.com"
                >
                  Get In Touch
                </a>
              </div>
            </div>
            <div className="small-12 column">
              <div className="my-arrow-div text-center">
                <a href="https://github.com/georgeuwabor">
                  <i className="fa fa-github-alt fa-2x"></i>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="experience">
          <div className="row column">
            <div className="small-12">
              <h2 className="text-center">My Experience</h2>
              <p className="text-center tag-line">
                Specialist in need. Generalist at heart.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-4 columns text-center experience-topics">
              <i className="fi-laptop"></i>
              <h3>Front-end</h3>
              <ul>
                <li>html(5)</li>
                <li>css(3)</li>
                <li>Javascript</li>
                <li>Jquery</li>
                <li>Bootstrap</li>
                <li>TypeScript</li>
                <li>GIT</li>
              </ul>
            </div>

            <div className="small-12 medium-4 columns text-center experience-topics">
              <i className="fi-star"></i>
              <h3>Tools</h3>
              <ul>
                <li>Angularjs</li>
                <li>Mac OS</li>
                <li>VsCode</li>
                <li>Reactjs</li>
                <li>Nodejs</li>
                <li>Github</li>
                <li>Npm</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="work">
          <h2 className="text-center">Work</h2>
          <div className="row">
            <p className="text-center tag-line">
              Here are few of my former clients.
            </p>
          </div>

          <div className="row work-row">
            <div className="small-12 medium-6 medium-push-6 columns">
              <img src="assets/images/Daizaura.jpeg" alt="" />
            </div>
            <div className="small-12 medium-5 medium-pull-7 columns">
              <div className="details details-ftm">
                <h3>Daizaura</h3>
                <p className="platform">Online Marketing Tool</p>
                <p className="icons">
                  <i className="fi-monitor"></i>
                  <i className="fi-tablet-portrait"></i>
                  <i className="fi-mobile"></i>
                </p>
                <p>
                  provides 100% unadulterated natural skin care products while
                  offering you valuable skin care advice and practical beauty
                  tips to ensure your skin is happy all year round.
                </p>
                <a
                  className="my-button"
                  target="_blank"
                  href="http://daizaura.com"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>

          <div className="row work-row">
            <div className="small-12 medium-6 columns">
              <img
                src="assets/images/orezime.jpeg"
                alt=""
                height="500"
                width="500"
              />
            </div>
            <div className="small-12 medium-5 medium-offset-1 columns">
              <div className="details details-ftm">
                <h3>Orezime</h3>
                <p className="platform">Online Learning Platform</p>
                <p className="icons">
                  <i className="fi-monitor"></i>
                  <i className="fi-tablet-portrait"></i>
                  <i className="fi-mobile"></i>
                </p>
                <p>
                  Africa's Largest Marketplace for online courses. Provides
                  knowledge from the best instructors from different parts of
                  the world to your grasp in the form of online classes.
                </p>
                <a
                  className="my-button"
                  target="_blank"
                  href="http://orezime.com"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>

          <div className="row work-row">
            <div className="small-12 medium-6 medium-push-6 columns">
              <img src="assets/images/Klarna.png" alt="" />
            </div>
            <div className="small-12 medium-5 medium-pull-7 columns">
              <div className="details details-ftm">
                <h3>Klarna</h3>
                <p className="platform">Modern Payment Method Platform</p>
                <p className="icons">
                  <i className="fi-monitor"></i>
                  <i className="fi-tablet-portrait"></i>
                  <i className="fi-mobile"></i>
                </p>
                <p>
                  With Klarna, shoppers get the financial freedom to split their
                  purchases into 4 interest-free payments. From pre-purchase
                  inspiration to post-purchase management.
                </p>
                <a
                  className="my-button"
                  target="_blank"
                  href="http://google.com"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>

          <div className="row work-row">
            <div className="small-12 medium-6 columns">
              <img
                src="assets/images/iesng.jpeg"
                alt=""
                height="500"
                width="500"
              />
            </div>
            <div className="small-12 medium-5 medium-offset-1 columns">
              <div className="details deatils-ftm">
                <h3>IESNG</h3>
                <p className="platform">Software Development.</p>
                <p className="icons">
                  <i className="fi-monitor"></i>
                  <i className="fi-tablet-portrait"></i>
                  <i className="fi-mobile"></i>
                </p>
                <p>
                  Technology solutions company active in the business of
                  Software development, Business Intelligence, Analytics, Skills
                  Acquisition and Cyber Security Consultancy. Build solutions,
                  maintain and support businesses with IT system selection,
                  procurement, custom development and integration considerations
                  for different industries.
                </p>
                <a
                  className="my-button"
                  target="_blank"
                  href="http://iesng.com"
                  rel="noreferrer"
                >
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="teaching">
          <h2 className="text-center">Autodidact...</h2>
          <div className="row">
            <div className="small-12 medium-8 medium-offset-2">
              <p className="text-center tag-line">
                In the last couple of years, I've learnt programming by myself,
                with the help of some mentors and tutorials both privately in
                person and remotely. Here are some of the websites for
                programmers that helped me expand my skillset has developer...
              </p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-8 medium-offset-2 columns">
              <div className="teaching-domains"></div>
            </div>
          </div>
        </section>

        <section id="footer-cta">
          <h2 className="text-center">Wanna work with me?</h2>
          <p></p>
          <div className="row text-center">
            <div className="small-12 medium-8 medium-offset-2 columns">
              <p className="text-center">I'm currently available for work.</p>
            </div>
            <div className="small-12 columns" id="startButtonDiv">
              <a
                className="my-button form-button"
                href="mailto:georgeuwabor@gmail.com"
              >
                Start Now
              </a>
            </div>
          </div>
        </section>

        <footer id="footer">
          <div className="row text-center">
            <div className="small-12 medium-6 medium-offset-3 columns">
              <p id="logo">GU</p>
              <p id="copyright">Copyright &copy; 2021 | George Uwabor</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Index;
